var site = site || {};

/**
 * This ADA class is used for Main Menu(gnav) Navigation, It is also used to access Popup windows
 * and also sets focus to Active Item using custom outline.
 * @param {e} keyCode value is passed to ADA class to define the corresponding action to proceed
 * such as Menu Navigation or Popup Window open.
 *
 * LIST OF METHODS AVAILABLE:
 * getKeycode - Method used to get keyCode value of keyboard event triggered.
 * site.restrict_navigation - Method used to retain Tab Focus within selected container.
 * primaryMenuNavigation - Method used to navigate through Main Menu and products by
 * tabsNavigation - Method used to access products placed within Tab controls.
 * productsNavigation - Method used to access each individual product placed within MPP product page
 * and enables to view product using QuickView mode
 * customFocusNavigation - Method used to set focus on active element with custom outline when
 * Tab key is pressed
 */

(function($) {
  Drupal.behaviors.ada = {
    attach: function(context) {
      var $prevActiveElem = null;
      // custom jquery function for binding enter key event
      $.fn.enterKey = function(fnc) {
        return this.each(function() {
          $(this).on('keydown', function(e) {
            var keycode = site.getKeycode(e);
            if (keycode === '13') {
              fnc.call(this, e);
            }
          });
        });
      };

      // to get keycode of keyboard events;
      site.getKeycode = function(e) {
        var keycode = e.keyCode ? e.keyCode : e.which;
        return keycode;
      };

      $('#cart', context).ajaxComplete(function() {
        var $selectContainer = $('.js-selctbox-qty:visible');
        $selectContainer.attr({'tabindex': '0', 'aria-expanded': 'false'}).focus();
      });

      // custom class defined for binding Keyboard "Enter Key" event
      $('.js-access-link', context).enterKey(function() {
        $(this).trigger('click');
      });

      // for retaining Tab Focus within Container given.
      site.restrict_navigation = function(element) {
        element.on('keydown', function(e) {
          if (e.keyCode !== 9 || e.isDefaultPrevented()) {
            return;
          }
          var $tabbables = element.find(':tabbable');
          var $first = $tabbables.filter(':first');
          var $last = $tabbables.filter(':last');
          var $target = e.target;
          if (($target === $last[0] || $target === element[0]) && !e.shiftKey) {
            $first.focus();
            e.preventDefault();
          } else if (($target === $first[0] || $target === element[0]) && e.shiftKey) {
            $last.focus();
            e.preventDefault();
          }
        });
      };

      // for primary nav
      var primaryMenuNavigation = function() {
        $('.js-main-menu', context).each(function() {
          var $this = $(this);
          if ($this.children().length > 1) {
            var label = $this.find('> :first').text();
            if ($this.find('> a').length > 0) {
              $this.find('> a').attr({
                'tabindex': '-1',
                'aria-hidden': 'true'
              });
            }
            $this.attr({
              'tabindex': 0,
              'aria-expanded': 'false',
              'aria-haspopup': 'true',
              'role': 'menu',
              'aria-label': label
            });
          }
        }).on({
          keydown: function(e) {
            var keyCode = site.getKeycode(e);
            var $this = $(this);
            switch (keyCode) {
              case 9: //  Tab
                var $link = e.shiftKey ? $this.prev() : $this.next();
                if ($this.hasClass('active')) {
                  if ($this.find('ul:last > li:last a').hasClass('custom-outline')) {
                    if (!e.shiftKey) {
                      $this.toggleClass('active').attr('aria-expanded', 'false');
                    }
                  }
                  if (e.shiftKey && $this.hasClass('custom-outline')) {
                    e.preventDefault();
                    $this.toggleClass('active').attr('aria-expanded', 'false');
                  }
                } else {
                  if ($link.length > 0 && !$this.hasClass('last')) {
                    e.preventDefault();
                    $link.children('a').length !== 1 ? $link.focus() : $link.children('a').focus();
                    return false;
                  }
                }
                break;
              case 13: //Enter Key
                $this.toggleClass('active');
                $this.attr('aria-expanded', $this.attr('aria-expanded') === 'true' ? 'false' : 'true');
                $this.children(':first').trigger('mouseover');
                break;
            }
          }
        });
        $('.js-header-search-trigger', context).on('keydown', function(e) {
          var $this = $(this);
          if (site.getKeycode(e) === 13) {
            $this.attr('aria-expanded', $this.attr('aria-expanded') === 'true' ? 'false' : 'true');
            $this.trigger('mouseover');
          }
        });
      };

      // for custom radio navigation at moisturizer page
      var customRadioNavigation = function() {
        $('[role="radio"]', context).on('keydown', function(e) {
          switch (site.getKeycode(e)) {
            case 37: //Left Arrow
              $(this).prev().focus();
              break;
            case 39: //Right Arrow
              $(this).next().focus();
              break;
            case 32: //Space
              e.preventDefault();
              break;
            case 13: //Enter
              if ($(this).find('span.active').length !== 1) {
                if ($(this).children('span').length === 1) {
                  $(this).children('span')[0].click();
                } else {
                  $(this).trigger('click');
                }
                $(this).attr({
                  'tabindex': '0',
                  'aria-checked': 'true'
                }).focus();
                $(this).siblings().each(function() {
                  $(this).attr({
                    'aria-checked': 'false',
                    'tabindex': '-1'
                  }).removeClass('custom-outline');
                });
              }
              break;
            case 9:
              if ($(this).parent().hasClass('js-filter-set')) {
                var $imageParent = $('.js-product-thumb--animate-abstract-touch');
                $imageParent.find('.js-product-thumb-image-link').removeAttr('tabindex');
                $imageParent.find('.js-product-thumb-image').removeAttr('tabindex role');
              }
              break;
          }
        });

        $('input:radio', context).focus(function() {
          var label = $(this).siblings('label').text();
          $(this).attr('aria-label', label);
          $(this).parent().addClass('custom-outline');
        }).focusout(function() {
          $(this).parent().removeClass('custom-outline');
        });

        $('input:checkbox', context).focus(function() {
          $(this).parent().addClass('custom-outline');
        }).focusout(function() {
          $(this).parent().removeClass('custom-outline');
        });
      };

      //Adding alt text for images in search results
      $('.js-search-form__submit', context).on('focus', function() {
        $('.js-product-image-wrapper', context).each (function() {
          var $control = $(this).parent().find('.js-product-result-header');
          $(this).find('img').attr('alt', $control.text());
        });
      });

      //Adding attributes and id in top-10 product
      $('.js-top_10-product__link a', context).on('focusin', function() {
        var $productNameLink = $(this).closest('.js-top_10-product__product').find('.js-top_10-product__product-name');
        var $productNameList = $productNameLink.text().split(' ').join('-');
        $productNameLink.attr('id', $productNameList);
        $(this).attr('aria-describedby', $productNameList);
      });

      //Adding custom outline in order history button
      $('.js-view_details-button', context).on('focusin', function() {
        $(this).parent().addClass('custom-outline');
      }).focusout(function() {
        $(this).parent().removeClass('custom-outline');
      });

      //CBox Close popup
      $('#cboxClose', context).focusout(function() {
        $(this).removeClass('custom-outline');
      });

      // for products and quickshop
      var productsNavigation = function() {
        var $parent = $('.js-product-grid', context);
        var $targetElem = $('.js-access-content, .js-access-button, .js-quickshop-link', context);
        var isHidden = $('.js-quickshop-link').is(':hidden');
        $parent.on('focusin focusout keydown', $targetElem, function(e) {
          var $elem = $(this);
          var keyCode = site.getKeycode(e);
          var eventType = e.type;
          switch (eventType) {
            case 'focusin':
              if ($(this).hasClass('js-access-content')) {
                $(this).addClass('js-focus-active');
              }
              //To make QuickShop Button on product item visible when focus is within container
              $elem.find('.product-thumb').removeClass('product-thumb--animate-cta');
              $('.product-thumb--price').click();
              break;
            case 'focusout':
              //To make QuickShop Button on product item invisible when container looses focus
              $elem.find('.product-thumb').addClass('product-thumb--animate-cta');
              break;
            case 'keydown':
              if (isHidden) {
                $elem.find('.product-thumb').addClass('product-thumb--animate-cta');
              }
              if (keyCode === 13 || keyCode === 32) {
                //To mark the product item of which quickshop overlay was triggered, so that focus can be returned
                //here when quickshop overlay is closed.
                if ($elem.hasClass('js-quickshop-trigger')) {
                  $elem.closest('.product-grid__item').addClass('js-active-qs');
                }
                //To focus quickshop overlay and restrict navigation within the overlay when product:quickshop is triggered
                $(document).on('MPP:productQV', function() {
                  var $quickshopContainer = $('.js-quickshop-container');
                  $('.js-access-content-expanded', $quickshopContainer).focus();
                  site.restrict_navigation($quickshopContainer);
                });
                //To return focus to product item when quickshop overlay is closed.
                if ($elem.hasClass('js-quickshop-close')) {
                  $('.js-active-qs').focus().removeClass('js-active-qs');
                }
                return false;
              } else if (keyCode === 37) {
                if ($elem.hasClass('product-grid__item')) {
                  $elem.prev('.js-access-content').focus();
                }
              } else if (keyCode === 39) {
                if ($elem.hasClass('product-grid__item')) {
                  $elem.next('.js-access-content').focus();
                }
              }
              break;
          }
        });
      };

      var customFocusNavigation = function() {
        $(window).keyup(function(e) {
          var keyCode = site.getKeycode(e);
          if (keyCode === 9 && $(document.activeElement).length) {
            // Remove custom outline class from previously targeted element
            if ($prevActiveElem) {
              $prevActiveElem.removeClass('custom-outline');
            }
            // Add custom outline class to current Active element
            $prevActiveElem = $(document.activeElement);
            $prevActiveElem.addClass('custom-outline');
          }
        });
      };

      customFocusNavigation();
      primaryMenuNavigation();
      site.applyTabsNavigation();
      customRadioNavigation();
      productsNavigation();
    }
  };
})(jQuery);

// Bind closing event for popups
$(document).bind('cbox_closed', function() {
  // Get the element which the focus should be returned to and the element which was deliberately hidden
  var $focusReturn = $('.js-return-focus');
  var $removeHidden = $('.js-remove-hidden');
  // Focus the element on closing
  $focusReturn.focus().removeClass('js-remove-hidden');
  // Unhide the next tabbable element
  if (typeof $removeHidden !== 'undefined') {
    $removeHidden.removeAttr('aria-hidden').removeClass('js-remove-hidden');
  }
});

$.curCSS = function(element, prop, val) {
  return $(element).css(prop, val);
};

site.applyTabsNavigation = function() {
  $('[role="tab"]').on('keydown', function(e) {
    var $this = $(this);
    var $prev = $this.prev();
    var $next = $this.next();
    var $target = [];
    var keyCode = site.getKeycode(e);
    var orientation = $this.data('role-orientation');
    var prevKey = orientation === 'vertical' ? 38 : 37; // Arrow Up : Arrow Left
    var nextKey = orientation === 'vertical' ? 40 : 39; // Arrow Down : Arrow Right

    switch (keyCode) {
      case prevKey:
        $target = $prev;
        break;
      case nextKey:
        $target = $next;
        break;
      case 9: // Tab
        $prev.attr('tabindex', '-1');
        $this.nextAll().attr('tabindex', '-1');
        $this.removeClass('custom-outline');
        break;
      // @todo check if space should be handled as "enter" as well
      case 13: // Enter
        if ($this.children('a').length === 1) {
          $this.children('a')[0].click();
        } else if ($this.children('span').length === 1) {
          $this.children('span').trigger('click');
        } else {
          $this.trigger('click');
        }
        break;
    }

    if ($target.length > 0) {
      $this.attr({
        'tabindex': '-1',
        'aria-selected': ''
      }).removeClass('custom-outline');
      $target.attr({
        'tabindex': '0',
        'aria-selected': true
      }).addClass('custom-outline').focus();
    }
  });
};

// To make jQuery selectBox accessible, used by script.js do not remove
function applySelectBoxAccessibility(context) {
  // Get all the select boxes within the context
  var selectBox = $('select', context);
  // Iterate each select box to make it accessible
  selectBox.each(function() {
    var control = $(this).next(),
        selectRequired = $(this).attr('aria-required'),
        selectLabel = $(this).attr('aria-label');
    var label = $(this).attr('id') + 'label';
    if (!control.hasClass('selectBox-dropdown')) {
      $(this).attr('role', 'combobox');
    } else {
      control.attr({
        'aria-haspopup': 'true',
        'aria-expanded': 'false',
        'role': 'combobox',
        'aria-required': selectRequired !== undefined ? selectRequired : 'false',
        'aria-describedby': label,
      });
      control.find('.selectBox-arrow').attr('aria-hidden', 'true');
      if (selectLabel !== undefined) {
        control.attr('aria-label', selectLabel);
        control.find('.selectBox-label').attr('id', label);
      }
      if ($(this).hasClass('js-sku-menu')) {
        $('.js-sku-menu').on('open.selectBox', function() {
          control.attr('aria-hidden', 'true');
          $('.js-sku-shade-name').removeAttr('aria-hidden');
        }).on('close.selectBox', function() {
          control.removeAttr('aria-hidden');
          $('.js-sku-shade-name').attr('aria-hidden', 'true');
        });
      }
      //Binding the required events
      $(this).off('focus open beforeclose').on({
        focus: function() {
          control = $(this).next();
        },
        open: function() {
          control.attr({
            'aria-expanded': 'true'
          });
        },
        beforeclose: function() {
          control.attr({
            'aria-expanded': 'false'
          });
        }
      });
    }
  });
}
